import { graphql, Link } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Footer } from '../components/Footer';
import { GamePostCard } from '../components/GamePostCard';
import SiteNav from '../components/header/SiteNav';
import { PostCard } from '../components/PostCard';
import { PostFullContent } from '../components/PostContent';
import { Wrapper } from '../components/Wrapper';
import IndexLayout from '../layouts';
import { inner, NoImage, outer, PostFeed, SiteHeader, SiteMain, SiteNavMain } from '../styles/shared';
import { PageContext, PostFull, PostFullHeader } from '../templates/post';
import config from '../website-config';

interface NotFoundTemplateProps {
  data: {
    allMarkdownRemark: {
      totalCount: number;
      edges: Array<{
        node: PageContext;
      }>;
    };
    // header: {
    //   childImageSharp: {
    //     fixed: FixedObject;
    //   };
    // };
  };
}

const HomePage: React.FC<NotFoundTemplateProps> = props => {
  const { edges } = props.data.allMarkdownRemark;
  // const { width, height } = props.data.header.childImageSharp.fixed;

  return (
    <IndexLayout>
      <Helmet>
        <html lang={config.lang} />
        <title>{config.title}</title>
        <meta name="description" content={config.description} />
        <meta property="og:site_name" content={config.title} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={config.title} />
        <meta property="og:description" content={config.description} />
        <meta property="og:url" content={config.siteUrl} />
        {/* <meta
          property="og:image"
          content={`${config.siteUrl}${props.data.header.childImageSharp.fixed.src}`}
        /> */}
        {config.facebook && <meta property="article:publisher" content={config.facebook} />}
        {config.googleSiteVerification && (
          <meta name="google-site-verification" content={config.googleSiteVerification} />
        )}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={config.title} />
        <meta name="twitter:description" content={config.description} />
        <meta name="twitter:url" content={config.siteUrl} />
        {/* <meta
          name="twitter:image"
          content={`${config.siteUrl}${props.data.header.childImageSharp.fixed.src}`}
        /> */}
        {config.twitter && (
          <meta
            name="twitter:site"
            content={`@${config.twitter.split('https://twitter.com/')[1]}`}
          />
        )}
        {/* <meta property="og:image:width" content={width.toString()} />
        <meta property="og:image:height" content={height.toString()} /> */}
      </Helmet>
      <Wrapper>
        <header css={[SiteHeader, outer]}>
          <div css={[outer, SiteNavMain]}>
            <div css={inner}>
              <SiteNav isHome={false} />
            </div>
          </div>
        </header>
        <main id="site-main" className="site-main" css={[SiteMain, outer]}>
          <div css={[inner]}>
            <article className="post page" css={[PostFull, NoImage]}>
              <PostFullHeader className="post-full-header" />
              <PostFullContent className="post-full-content">
                <div className="post-content">
                  <br />
                  <h6>
                    Welcome
                  </h6>
                  <p>
                    Hey, y'all! <br /><br />I'm Kazuya, a software engineer and aspiring game developer. <br />Currently working with Godot game engine.<br /><br />Feel free to check out my projects and leave some feedback.<br />I'd love to hear from you!<br /><br />
                    <Link to="https://kazzuyak.itch.io/" activeClassName="nav-current">kazzuyak.itch.io</Link>
                  </p>
                </div>
              </PostFullContent>
            </article>

            <hr id="games"/>

            <h2>
              Games
            </h2>
            <div css={PostFeed} className="post-feed">
              {edges.map(({ node }) => {
                if (!node.fields.slug.startsWith('/games/')) {
                  return;
                }

                return (
                  <GamePostCard key={node.fields.slug} post={node} />
                );
              })}
            </div>

            <hr id="minigame"/>

            <h2>
              Minigames
            </h2>
            Games that I developed for practicing and learning.
            <div css={PostFeed} className="post-feed">
              {edges.map(({ node }) => {
                if (!node.fields.slug.startsWith('/minigame/')) {
                  return;
                }

                return (
                  <PostCard key={node.fields.slug} post={node} />
                );
              })}
            </div>

            <hr id="blog"/>

            <h2>
              Blog Posts
            </h2>
            <div css={PostFeed} className="post-feed">
              {edges.map(({ node }) => {
                if (!node.fields.slug.startsWith('/blog/')) {
                  return;
                }

                return (
                  <PostCard key={node.fields.slug} post={node} />
                );
              })}
            </div>
          </div>
        </main>
        <Footer />
      </Wrapper>
    </IndexLayout>
  );
};

export const pageQuery = graphql`
  query {
    allMarkdownRemark(limit: 30, sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          frontmatter {
            title
            excerpt
            date
            tags
            image {
              childImageSharp {
                fluid(maxWidth: 3720) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            author {
              id
              bio
              avatar {
                children {
                  ... on ImageSharp {
                    fluid(quality: 100, srcSetBreakpoints: [40, 80, 120]) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
          excerpt
          fields {
            readingTime {
              text
            }
            layout
            slug
          }
        }
      }
    }
  }
`;

export default HomePage;
